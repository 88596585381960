@import './Settings.css';

.UserProfileMenu__avatar {
    width: 24px;
    height: 24px;
}

.UserProfileMenu__profile {
    display: flex;
    flex-direction: row;
    color: inherit !important;
    text-decoration: none !important;
}

.UserProfileMenu__profilePicture {
    width: 64px;
    height: 64px;
    border-radius: 4px;
}
.UserProfileMenu__profileData {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 16px;
    white-space: nowrap;
    hyphens: none;
}

.UserProfileMenu__profileName {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.4;
    margin-top: -5px;
}

.UserProfileMenu__profileEmail {
    line-height: 1.25;
    color: var(--color-gray-700);
    margin-top: -1px;
    margin-bottom: 8px;
}

.UserProfileMenu__profileUserId {
    font-size: 80%;
    line-height: 1.25;
    color: var(--color-gray-600);
    margin-top: 16px;
    margin-bottom: -3px;
}

.UserProfileMenu__profileLink {
    box-shadow: inset 0 -1px 0 0 var(--color-gray-800);
    padding-bottom: 1px;
}
