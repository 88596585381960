@import './Settings.css';

.LearnContent {
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.LearnContent__material {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
}

.LearnContent__menu {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 60px;
    margin-left: 16px;
    margin-right: 24px;
    margin-top: 0;
    padding-top: 24px;
    width: 230px;
    max-width: 230px;
    min-width: 230px;
}

.LearnContent__description {
    max-width: 750px;
    padding: 0 16px;
    font-size: 18px;
    flex: 1;
}

.LearnSection__rule {
    height: 0px;
    border: none;
    border-top: 1px solid var(--color-gray-300);
}

.LearnSection {
    margin: 40px 0;
}

.LearnSection__title {
    margin-top: 30px;
}

.LearnSubSection {
    display: flex;
    flex-direction: row;
    margin: 40px 0;
}

.LearnSubSection__image {
    margin-bottom: auto; 
    width: 40%;
    max-width: 300px;
    display: inline-block;
    margin-right: 32px;
}

.LearnSubSection__text {
    flex: 1;
    margin: 0;
}

.LearnSubSection__text p {
    margin-block-start: 0;
    margin-block-end: 0.5em;
}

.LearnSubSection_linkIcon {
    position: relative;
    top: 3px;
    margin-left: 4px;
}

@media only screen and (max-width: 850px) {
    .LearnContent__menu {
        display: none;
    }
}

@media only screen and (max-width: 600px) {
    .LearnSubSection {
        flex-direction: column;
    }
    .LearnSubSection__image {
        width: 100%;
        max-width: 400px;
        margin: 0 auto;
    }
    .LearnSubSection__text {
        margin: 1em 0 0 0;
    }
}