@import './Settings.css';

.Footer {
    flex: none;
}

.Footer--overlay {
    position: absolute;
    bottom: 0;
    right: 0;
    width: auto;
    background: none;
}

.Footer--no-overlay {
    position: relative;
    width: 100%;
    background: var(--color-gray-50);
}

.privacy-consent-link {
    float: right;
    margin: 12px 40px 4px;
    display: flex;
    bottom: 0;
    right: 0;
    border: 1px solid transparent;
    padding: 2px 4px;
    color: var(--color-gray-900);
    cursor: pointer;
}

.privacy-consent-link:focus {
    outline: none;
    border-radius: 4px;
    border-color: var(--color-static-blue-500);
    box-shadow: 0 0 0 1px var(--color-static-blue-500);
}
