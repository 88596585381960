@import './Settings.css';

.CreaseVisualizer {
    line-height: 0;
    z-index: 1;
}

.CreaseVisualizer__svg {
    background: var(--color-gray-100);
    stroke-linecap: round;
    stroke-linejoin: round;
    overflow: visible !important;
}

.CreaseVisualizer__popover {
    padding: 0 16px 0 16px;
    transform: translate(10px, 190px);
}

.CreaseVisualizer__item {
    margin-top: 16px;
    margin-bottom: 8px;
}

.CreaseVisualizer__itemHeading {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.CreaseVisualizer__alert--warning {
    color: var(--color-import-warning) !important;
}
.CreaseVisualizer__alert--error {
    color: var(--color-import-error) !important;
}

.CreaseVisualizer__itemTitle {
    margin-left: 8px;
}

.CreaseEdgeVisualizer {
    fill: none;
    stroke-width: 1;
    vector-effect: non-scaling-stroke;
}
.CreaseEdgeVisualizer--crease {
    stroke: var(--color-edge-crease);
}
.CreaseEdgeVisualizer--cut {
    stroke: var(--color-edge-cut);
}

.CreaseFaceVisualizer {
    fill: var(--color-gray-300);
    stroke: var(--color-gray-100);
    stroke-width: 6;
    vector-effect: non-scaling-stroke;
}

.CreaseFaceVisualizer--problem {
    fill: rgba(255, 136, 0, 0.5);
}

.ProblemAreaVisualizer {
    cursor: pointer;
}

.ProblemAreaVisualizer:focus {
    outline: none;
}

.ProblemAreaVisualizer__circle {
    fill-opacity: 0.15;
    stroke-width: 1;
    vector-effect: non-scaling-stroke;
}
.ProblemAreaVisualizer__circle--warning {
    fill: var(--color-import-warning);
    stroke: var(--color-import-warning);
}
.ProblemAreaVisualizer__circle--error {
    fill: var(--color-import-error);
    stroke: var(--color-import-error);
}

.ProblemAreaVisualizer:hover .ProblemAreaVisualizer__circle {
    stroke-width: 2;
}

.ProblemAreaVisualizer__focus {
    visibility: hidden;
}

.ProblemAreaVisualizer:focus .ProblemAreaVisualizer__focus {
    visibility: visible;
    fill: none;
    stroke: var(--color-static-blue-600);
    stroke-width: 2;
    vector-effect: non-scaling-stroke;
}

.SelectedProblemAreaVisualizer__underlay {
    fill: var(--color-static-black);
    fill-opacity: 0.5;
}

.SelectedProblemAreaVisualizer {
    cursor: pointer;
}

.SelectedProblemAreaVisualizer__background {
    fill: var(--color-gray-50);
}

.SelectedProblemAreaVisualizer__rim {
    fill: none;
    stroke-width: 1;
    vector-effect: non-scaling-stroke;
}
.SelectedProblemAreaVisualizer__rim--warning {
    stroke: var(--color-import-warning);
}
.SelectedProblemAreaVisualizer__rim--error {
    stroke: var(--color-import-error)
}

.ProblemVertexVisualizer {
    stroke: var(--color-gray-50);
    stroke-width: 1;
    vector-effect: non-scaling-stroke;
}
.ProblemVertexVisualizer--warning {
    fill: var(--color-import-warning);
}
.ProblemVertexVisualizer--error {
    fill: var(--color-import-error);
}
