@import './Settings.css';

.RightPanel {
    width: 224px;
    background: var(--color-gray-100);
    margin-top: 16px;
    margin-right: 16px;
    border-radius: var(--corner-radius);
    padding: 8px 24px 16px;
    box-shadow: var(--shadow-panel);
}
