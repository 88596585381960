@import './Settings.css';

.ToolPanel {
  background: var(--color-gray-100);
  display: inline-block;
  margin-top: 16px;
  margin-left: 16px;
  border-radius: var(--corner-radius);
  padding: 8px 4px;
  box-shadow: var(--shadow-panel);
  position: absolute;
  top: var(--header-height);
}
