/* Spectrum colors: see https://spectrum.corp.adobe.com/page/color/ */
.spectrum--dark {
    --color-gray-50: rgb(37, 37, 37);
    --color-gray-75: rgb(47, 47, 47);
    --color-gray-100: rgb(50, 50, 50);
    --color-gray-200: rgb(62, 62, 62);
    --color-gray-300: rgb(74, 74, 74);
    --color-gray-400: rgb(90, 90, 90);
    --color-gray-500: rgb(110, 110, 110);
    --color-gray-600: rgb(144, 144, 144);
    --color-gray-700: rgb(185, 185, 185);
    --color-gray-800: rgb(227, 227, 227);
    --color-gray-900: rgb(255, 255, 255);
}
.spectrum--light {
    --color-gray-50: rgb(255, 255, 255);
    --color-gray-75: rgb(250, 250, 250);
    --color-gray-100: rgb(245, 245, 245);
    --color-gray-200: rgb(234, 234, 234);
    --color-gray-300: rgb(225, 225, 225);
    --color-gray-400: rgb(202, 202, 202);
    --color-gray-500: rgb(179, 179, 179);
    --color-gray-600: rgb(142, 142, 142);
    --color-gray-700: rgb(110, 110, 110);
    --color-gray-800: rgb(75, 75, 75);
    --color-gray-900: rgb(44, 44, 44);
}
.spectrum--dark, .spectrum--light {
    --color-static-black: rgb(0, 0, 0);
    --color-static-white: rgb(255, 255, 255);
    --color-static-blue-400: rgb(55, 142, 240);
    --color-static-blue-500: rgb(38, 128, 235);
    --color-static-blue-600: rgb(20, 115, 230);
    --color-static-blue-700: rgb(13, 102, 208);
    --color-static-red-400: rgb(236, 91, 98);
    --color-static-red-500: rgb(227, 72, 80);
    --color-static-red-600: rgb(215, 55, 63);
    --color-static-red-700: rgb(201, 37, 45);
    --color-static-orange-400: rgb(242, 148, 35);
    --color-static-orange-500: rgb(230, 134, 25);
    --color-static-orange-600: rgb(218, 123, 17);
    --color-static-orange-700: rgb(203, 111, 16);
    --color-static-green-400: rgb(51, 171, 132);
    --color-static-green-500: rgb(45, 157, 120);
    --color-static-green-600: rgb(38, 142, 108);
    --color-static-green-700: rgb(18, 128, 92);
}

/* Application colors and settings */
.spectrum--dark, .spectrum--light {
    /* 3D view */
    --color-3D-background: var(--color-gray-300);
    --color-3D-grid: var(--color-gray-800);
    --color-edge-cut: var(--color-gray-700);
    --color-edge-crease: var(--color-static-red-400);
    --color-edge-constraint: var(--color-static-orange-400);
    --color-edge-selection: var(--color-static-blue-500);
    --color-edge-hover: var(--color-static-blue-400);
    --color-face-selection: var(--color-static-blue-500);
    --color-face-hover: var(--color-static-blue-400);

    /* Fold angle tool */
    --color-gizmo-ring: var(--color-gray-600);
    --color-gizmo-handle-outline: var(--color-static-blue-500);
    --color-gizmo-handle-hover: var(--color-static-blue-400);
    --color-gizmo-handle-fill: var(--color-static-white);
    --color-gizmo-right-angle: cyan;

    /* Marquee selection tool */
    --color-marquee-selection: var(--color-static-blue-400);

    /* Scribble selection tool */
    --color-scribble: var(--color-gray-900);

    /* Import dialog */
    --color-import-success: var(--color-static-green-700);
    --color-import-error: var(--color-static-red-600);
    --color-import-warning: var(--color-static-orange-400);
    --color-import-error-transparent: rgba(215, 55, 63, 0.15); /* static red 600 */
    --color-import-warning-transparent: rgba(242, 148, 35, 0.15); /* static orange 400 */

    /* Panel shadows */
    --shadow-panel: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.09);

    /* Sizes */
    --corner-radius: 10px;
    --min-width-app: 850px;
    --min-height-app: 694px;
    --header-height: 48px;
}
