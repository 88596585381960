@import './Settings.css';

.AppHeader {
    display: flex;
    height: var(--header-height);
    min-height: var(--header-height);
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    white-space: nowrap;
    background: var(--color-gray-100);
    min-width: var(--min-width-app);
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    z-index: 2; /* This is needed for sticky behavior to draw on top of learn side menu */
}

.AppHeader__minimal {
    min-width: 500px;
}

.AppHeader__left {
    margin-left: 35px;
    display: flex;
    align-items: center;
    width: 400px;
}

.AppHeader__center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 0 0;
    margin: 0 20px;
}

.AppHeader__right {
    margin-right: 35px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 400px;
}

.AppHeader__logo {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

.AppHeader__title {
    font-weight: 700;
    color: var(--color-gray-700);
    display: inline-block;
    text-decoration: none;
}

.AppHeader__beta {
    margin-left: 8px;
    color: var(--color-gray-400);
    border: 1px solid var(--color-gray-400);
    border-radius: 3px;
    padding: 0 4px;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}

.AppHeader .spectrum-Tabs {
    align-self: stretch;
    margin-left: 16px;
    margin-bottom: -2px;
    border-bottom-color: transparent !important;
}

.AppHeader .spectrum-Tabs-itemLabel {
    font-size: 16px;
    height: 46px;
}

.AppHeader .spectrum-Tabs-item {
    padding: 0 10px;
}

.AppHeader__divider {
    margin: 16px;
    background: var(--color-gray-400);
    width: 1px;
    height: 24px;
}

.AppHeader__menuItemLabel {
    display: flex;
}

.AppHeader__menuItemIcon {
    align-self: center !important;
    margin-left: 8px;
}

.AppHeader__column {
    display: flex;
    flex-direction: column;
}
