.KeyboardShortcutLabel--key {
    display: inline-flex;
    justify-content: center;
    font-family: inherit;
    font-weight: 600;
    line-height: 80%;
    border-width: 1px;
    border-style: solid;
    border-color: initial;
    border-radius: 3px;
    min-width: 16px;
    padding: 2px 3px 3px 3px;
    margin-left: 4px;
    box-sizing: border-box;
}
