.HelpModal {
    padding: 0;
}

.HelpModal__card {
    min-width: 550px;
    padding: 0 20px;
    text-align: center;
    padding-bottom: 8px;
}

.HelpModal .spectrum-Card-coverPhoto {
    min-height: 300px;
    height: 300px;
    margin: 20px 0;
    border: none;
}

.HelpModal__body {
    text-align: center;
    padding: 30px;
    font-size: 20px;
    max-width: 350px;
    margin: auto;
}