.AppContent {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    min-width: var(--min-width-app);
    overflow-x: hidden;
    overflow-y: hidden;
}

.AppContent--home {
    overflow-y: auto;
}

.AppContent:not(.AppContent--home) {
    min-height: var(--min-height-app);
}

.AppContent__rightPanel {
    position: absolute;
    top: var(--header-height);
    right: 0;
}

.AppContent__closePreview {
    margin: 32px;
}
