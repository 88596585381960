@import './Settings.css';

.ImportArtworkModal .row {
    display: flex;
    flex-direction: row;
}

.ImportArtworkModal__column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ImportArtworkModal__svgContainer {
    height: 300px;
    border: 2px solid var(--color-gray-200);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
}

.ImportArtworkModal__svgContainer > svg {
    height: 300px;
}

.ImportArtworkModal__svgContainer--Interior > svg {
    transform: scaleX(-1);
}

.ImportArtworkModal__svgContainer > svg * {
    vector-effect: non-scaling-stroke;
}
.ImportArtworkModal__svgContainer > svg .crease {
    stroke: var(--color-edge-crease) !important;
}
.ImportArtworkModal__svgContainer > svg .cut {
    stroke: var(--color-edge-cut) !important;
}

.ImportArtworkModal__instructions {
    max-width: 420px;
}