@import './Settings.css';

.Home {
    background: var(--color-gray-50);
    padding: 24px 40px 5px 40px;
    flex: 1;
}

.Home__welcome, .Home__learn {
    border: 2px solid var(--color-gray-300);
    border-radius: 4px;
}

.Home__welcome {
    padding: 30px;
    display: flex;
    flex-direction: row;
}

.Home__intro {
    align-items: flex-start;
    text-align: left;
    flex: none;
    margin-top: 8px;
    width: 256px;
    font-size: 12pt;
}

.Home__examples {
    flex: 1;
    display: flex;
    flex-flow: row wrap
}

.Home__examplesRow {
    margin-top: 20px;
    display: flex;
    flex: 1;
    min-width: 350px;
    width: 200px;
}

.Home__example {
    flex: 1;
    height: 100%;
    text-align: center;
    margin: 0 20px;
}

.Home__example img {
    cursor: pointer;
    margin-bottom: 8px;
    width: 100%;
    max-width: 300px;
}

.spectrum--dark .Home__example img {
    filter: invert(0.855);
}

.Home__bottomRow {
    margin-top: 32px;
    display: flex;
    flex-direction: row;
}

.Home__learn {
    flex: none;
    padding: 8px 30px 30px 30px;
    width: 250px;
    margin-right: 32px;
    font-size: 12pt;
}

.Home__dropZone {
    background: rgba(128, 128, 128, 0.05);
    padding: 32px 72px;
    flex: 1;
}

.Home__learnBullets {
    list-style-type: none;
    padding: 0;
    margin: 0 0 20px 0;
}

.Home__numberBullet {
    display: inline-block;
    background: var(--color-static-blue-400);
    width: 30px;
    height: 30px;
    border-radius: 15px;
    line-height: 28px;
    color: var(--color-static-white);
    text-align: center;
    font-weight: 900;
    margin-top: 10px;
    margin-right: 10px;
}

.Home__icon {
    position: relative;
    top: 3px;
    margin-left: 4px;
}