@import './Settings.css';

#View3DContainer {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: var(--color-3D-background);
    overflow: hidden;
}
