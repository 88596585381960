.FoldAngleTool__tooltip {
    position: absolute;
    pointer-events: none;
}

.FoldAngleTool__tooltip .spectrum-Tooltip-label {
    min-width: 32px;
    text-align: center;
}

.FoldAngleTool__tooltip .spectrum-Tooltip-tip {
    display: none;
}
