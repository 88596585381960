@import './Settings.css';

.ImportDielineModal {
    width: auto;
    overflow-y: auto;
}

.ImportDielineModal .spectrum-Dialog-content {
    /* Keep content in front of header and footer and allow overflow. */
    z-index: 1;
    overflow: visible;
}

.ImportDielineModal__content {
    display: flex;
    flex-direction: row;
}

.ImportDielineModal__leftColumn {
    display: flex;
    flex-direction: column;
    max-width: 504px;
}

.ImportDielineModal__row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ImportDielineModal__wait {
    margin: 0 8px -2px 0;
}

.ImportDielineModal__tabIcon {
    opacity: 0.75;
}

.ImportDielineModal__tabItem.is-selected .ImportDielineModal__tabIcon,
.ImportDielineModal__tabItem:hover .ImportDielineModal__tabIcon {
    opacity: 1;
}

.ImportDielineModal__svgContainer {
    width: 504px;
    height: 304px;
    line-height: 0;
    position: relative;
}

.ImportDielineModal__svgContainer > svg, .ImportDielineModal .CreaseVisualizer__svg {
    width: 500px;
    height: 300px;
    border: 2px solid var(--color-gray-200);
    border-top-width: 0;
}

.ImportDielineModal__svgContainer > svg {
    position: absolute;
}

.ImportDielineModal__svgContainer > svg * {
    vector-effect: non-scaling-stroke;
}
.ImportDielineModal__svgContainer > svg .crease {
    stroke: var(--color-edge-crease) !important;
}
.ImportDielineModal__svgContainer > svg .cut {
    stroke: var(--color-edge-cut) !important;
}

.ImportDielineModal__noPanels {
    text-anchor: middle;
    font-size: 100px;
    font-family: 'Adobe Clean', sans-serif;
    fill: var(--color-gray-400);
}

.ImportDielineModal__legend {
    height: 56px;
    display: flex;
    flex-direction: row;
    align-self: center;
}

.ImportDielineModal__legendItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 32px;
}

.ImportDielineModal__legendItem:first-child {
    margin-left: 0;
}

.ImportDielineModal__colorSwatch {
    width: 32px;
    height: 2px;
    margin: 16px 8px 16px 0;
}
.ImportDielineModal__colorSwatch--cuts {
    background: var(--color-edge-cut);
}
.ImportDielineModal__colorSwatch--creases {
    background: var(--color-edge-crease);
}
.ImportDielineModal__colorSwatch--panels, .ImportDielineModal__colorSwatch--problems {
    width: 24px;
    height: 24px;
    background: var(--color-gray-400);
}

.ImportDielineModal__colorSwatch--problems {
    border-radius: 20px;
    background: none;
}

.ImportDielineModal__problemArea {
    width: 20px;
    height: 20px;
    border-radius: 12px;
    margin: 1px;
}
.ImportDielineModal__problemArea--warning {
    background: var(--color-import-warning-transparent);
    border: 1px solid var(--color-import-warning);
}
.ImportDielineModal__problemArea--error {
    background: var(--color-import-error-transparent);
    border: 1px solid var(--color-import-error);
}

.ImportDielineModal__instructions {
    min-width: 224px;
    max-width: 224px;
    margin-left: 24px;
}

.ImportDielineModal .spectrum-BarLoader-fill {
    transition: width 0s;
}

.ImportDielineModal__instructionsHeading {
    font-size: 115%;
    line-height: 200%;
}

.ImportDielineModal__error {
    color: var(--color-import-error) !important;
}

.ImportDielineModal__alert--warning {
    color: var(--color-import-warning) !important;
}
.ImportDielineModal__alert--error {
    color: var(--color-import-error) !important;
}

.ImportDielineModal__checkmark {
    color: var(--color-import-success) !important;
}

.ImportDielineModal__status {
    margin-left: 8px;
}
