@import './Settings.css';

.PackagePropertiesPanel {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.PackagePropertiesPanel__substrateSelect {
    width: 100%;
    margin-bottom: 8px;
}

.PackagePropertiesPanel__row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.PackagePropertiesPanel__artwork {
    width: 30px;
    height: 30px;
    border: 1px solid var(--color-gray-300);
    border-radius: 4px;
    object-fit: cover;
    margin-right: 8px;
}

.PackagePropertiesPanel__sideLabel {
    width: 80px;
    font-weight: 700;
    font-size: 14px;
    color: var(--color-gray-700);
}

.PackagePropertiesPanel__addArtworkButton {
    margin-left: -4px;
    padding-left: 10px;
    align-self: flex-start;
}

.PackagePropertiesPanel__addArtworkButton .spectrum-Button-label {
    text-align: left;
}

.PackagePropertiesPanel__addIcon {
    margin-right: 6px;
}

.PackagePropertiesPanel__swapButton {
    align-self: flex-end;
    margin: -8px 0;
}

.PackagePropertiesPanel__swapButton.is-disabled {
    opacity: 0;
}

.PackagePropertiesPanel__swapIcon {
    transform: rotate(90deg);
}

.PackagePropertiesPanel__groupContainer {
    margin-bottom: 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.PackagePropertiesPanel__groupContainer:last-child {
    margin-bottom: 0;
}

.PackagePropertiesPanel__groundFaceSelectionButton--selected {
    background-color: var(--color-static-blue-600) !important;
    border-color: var(--color-static-blue-600) !important;
    color: var(--color-static-white) !important;
}

.PackageProperties__substrateIcon {
    stroke: var(--color-gray-900);
}
