@import './Settings.css';

.FoldAnglePopover {
    position: absolute;
    display: flex;
    flex-direction: row;
    background: var(--color-gray-100);
    border-radius: var(--corner-radius);
    padding: 4px 4px 8px 8px;
    box-shadow: var(--shadow-panel);
}
