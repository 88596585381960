@import './Settings.css';

/* Disallow selection of UI text. */
.App {
    user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    background: var(--color-gray-50);
    overflow-x: auto;
    overflow-y: hidden;
}

.App {
  width: 100%;
  height: 100%;
}

/* Use Adobe Clean font everywhere. Needed for OneTrust banner, dialog, and notification. */
body {
  font-family: adobe-clean,sans-serif;
}
