@import './Settings.css';

.AnimationPanel {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 16px 16px 32px;
    min-width: 224px;
    height: 136px;
    background: var(--color-gray-100);
    border-radius: var(--corner-radius);
    box-shadow: var(--shadow-panel);
    display: flex;
    flex-direction: row;
    align-items: stretch;
}

.AnimationPanel--expanded {
    left: 156px;
}

.AnimationPanel--preview {
    height: 78px;
    padding: 16px 24px;
}

.AnimationPanel__row {
    display: flex;
    flex-direction: row;
}

.AnimationPanel__keyframes {
    display: flex;
    flex-direction: column;
    flex: 1 0;
    overflow: hidden;
    padding: 8px 24px 8px;
    border-right: 2px solid var(--color-gray-300) !important;
}

.AnimationPanel__keyframeContainer {
    flex: 1 0;
    display: flex !important;
    align-items: center;
    min-width: auto !important;
    min-height: 60px;
    padding: 4px !important;
    margin: 0 24px 0 0 !important;
    overflow-x: auto;
}

.AnimationPanel__expandCollapseButton {
    align-self: stretch;
    height: auto;
    padding: 0 4px !important;
    border-radius: var(--corner-radius) 0 0 var(--corner-radius);
    border-right-style: solid;
    border-right-width: 1px;
}

.AnimationPanel__expandCollapseButton:not(.focus-ring) {
    border-right-width: 2px;
    border-right-color: var(--color-gray-300) !important;
}

.AnimationPanel__durations {
    display: flex;
    flex-direction: column;
}

.AnimationPanel__durationLabel label {
    width: 60px;
}

.AnimationPanel__numberInput {
    width: 48px;
}

.AnimationPanel__playback {
    display: flex;
    flex-direction: column;
    min-width: 224px;
    padding: 8px 24px 16px;
}

.AnimationPanel__playbackContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* height: 64px; */
    flex: 1 0;
}

.AnimationPanel__addKeyframesMessage {
    margin-top: 16px;
}

.AnimationPanel__playbackControlsRow {
    display: flex;
    justify-content: center;
}

.AnimationPanel__timelineRow {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.AnimationPanel__timelineSlider {
    flex: 1 0;
    margin-right: 16px;
}

.AnimationKeyframe {
    position: relative;
    width: 80px;
    height: 60px;
}

.AnimationKeyframe + .AnimationKeyframe, .AnimationKeyframe + .AnimationPanel__addKeyframeButton {
    margin-left: 8px;
}

.AnimationKeyframe__actions {
    position: absolute;
    bottom: 1px;
    right: 1px;
    display: flex;
    justify-content: flex-end;
}

.AnimationKeyframe__actionButton {
    height: 20px;
    min-width: 20px;
    padding: 0;
}

.AnimationKeyframe__actionButton svg {
    filter: drop-shadow(0 0 1px var(--color-gray-50));
}

.AnimationKeyframe__button {
    width: 80px;
    height: 60px;
    background-size: contain;
    background-repeat: no-repeat;
}

.AnimationKeyframe__button.is-selected {
    border-color: var(--color-static-blue-500) !important;
}
