@import './Settings.css';

.SignUp {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    background: url('../assets/backgrounds/background.png');
    background-position: center center;
    background-size: cover;
}

.SignUp__center {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    padding-top: 15px;
}

.SignUp__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    background: var(--color-gray-50) !important;
    border-radius: var(--corner-radius);
    padding: 35px;
    margin: auto;
    text-align: center;
    font-size: 12pt;
    width: 350px;
}

.SignUp__card > * {
    display: inline-block;
}

.SignUp__card > *:first-child {
    margin-top: 0px;
}

.SignUp__image {
    width: 200px;
    margin-top: 20px;
}

.spectrum--dark .SignUp__image {
    filter: invert(0.855);
}
