@import './Settings.css';

.Minimap, .Minimap:hover {
    background: var(--color-gray-100) !important;
    margin-bottom: 32px;
    margin-left: 16px;
    border-width: 2px;
    border-radius: var(--corner-radius);
    padding: 0 0 4px 0;
    box-shadow: var(--shadow-panel) !important;
    position: absolute;
    bottom: 0;
    overflow: hidden;
    height: 136px;
    display: flex;
    flex-direction: column;
}

.Minimap:hover {
    background: var(--color-gray-200) !important;
    border-color: transparent !important;
}

.Minimap__image {
    width: 120px;
    margin: 8px 8px 2px;
    pointer-events: none;
}
