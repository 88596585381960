@import './Settings.css';

.EdgeLabel {
    position: absolute;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    line-height: 1;
    min-height: 20px;
    padding: 0 4px;
    border-radius: 4px;
    background: var(--color-static-orange-400);
    color: var(--color-static-white);
}

.EdgeLabel--selected {
    background: var(--color-static-blue-500);
}

.EdgeLabel:hover, .EdgeLabel:active {
    background: var(--color-static-blue-400);
}
